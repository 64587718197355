.App {
  text-align: center;
  width: 100%;
  max-width: 100%;
  padding: auto;
  height: 100%;
  margin: 3px;
  margin-top: 20px;
  /* text-transform: uppercase; */
  /* text-decoration: red; */
  /* color: rgb(6, 199, 54); */

}

.nz-center {
  text-align: center;
  width: 100%;
  max-width: 100%;
  padding: 140px 40px 0 40px;
}

.nz-center-shownz {
  text-align: center;
  width: 100%;
  max-width: 100%;
  padding: 40px 20px 0 20px;
}

.nz-toast {
  text-align: center;
  width: 100%;
  max-width: 100%;
  padding: 140px 40px 0 40px;
}

.nz-line {
  text-align: center;
  width: 100%;
  max-width: 100%;
  padding: 40px 40px 0 40px;
}


.nz-logo {
  /* padding: 10px; */
  padding: 10px 0 0 10px;
  color: rgb(56, 175, 20)
}

.nz-spinner {
  padding: 5px 0 0 5px;
  color: olivedrab
}

.nz-info {
  font-size: 90%;
  font-family: Arial, Helvetica, sans-serif
}

.nz-error-inline {
  color: red;
  font-size: 0.9rem;
}

.nz-nz {
  font-size: 130%;

}

/* SEO content */
.nz-about {
  color: rgb(92, 90, 90);
  font-size: 90%;
  font-family: Arial, Helvetica, sans-serif
}

.nz-card {
  margin-top: 10px;
}